import "./App.css";
import React, { useRef } from "react";
const $ = document.querySelector.bind(document);

const button = document.querySelector("#myButton");
// Function to move the button 5 times with a delay

let moveCount = 0;

const MESSAGES = [
  "Okay Sorry, Here is your big surprise!",
  "Please click me softly now",
  "Last Chance! Click now",
  "Why are you so impatient ?",
  "Ready ?",
];

function App() {
  const [messageIdx, setMessageIdx] = React.useState(0);
  // const [showBanner, setShowBanner] = React.useState(false);
  const buttonRef = useRef();

  function moveButton(x, y) {
    setMessageIdx((prev) => prev + 1);
    buttonRef.current.style.left = `${x}px`;
    buttonRef.current.style.top = `${y}px`;
  }

  function getRandomCoordinates() {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const x = Math.floor(
      Math.random() * (viewportWidth - buttonRef.current.offsetWidth)
    );
    const y = Math.floor(
      Math.random() * (viewportHeight - buttonRef.current.offsetHeight)
    );
    return { x, y };
  }

  function moveButtonFiveTimes() {
    console.log("moveCount", moveCount);
    if (moveCount < 4) {
      const { x, y } = getRandomCoordinates();
      moveButton(x, y);
      moveCount++;
    } else {
      let redirectTo = "https://www.youtube.com/watch?v=TPiq6UwJZYw";
      window.location.href = redirectTo;
    }
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="toplayer">
        <button id="hi" onClick={() => $(".layer").classList.add("clicked")}>
          Click Here
        </button>
      </div>
      <div className="layer">
        <i
          className="fa fa-remove"
          id="remove"
          onClick={() => $(".layer").classList.remove("clicked")}
        ></i>
        <p>Thanks for clicking Deeksha!.</p>
        <p>Hope you'll have an awesome day!</p>
        <button
          ref={buttonRef}
          onClick={moveButtonFiveTimes}
          className="absolute bottom-72 text-white w-96 h-12 bg-pink-500 p-4 rounded-full hover:bg-pink-700 shadow-md transition-all duration-300"
          target="_blank"
        >
          {MESSAGES[messageIdx]}
        </button>
      </div>
    </div>
  );
}

export default App;
